import GLightbox from 'glightbox';

const lightbox = GLightbox({
  touchNavigation: true,
  loop: false,
  autoplayVideos: true,
  plyr: {
    captions: { active: false, },
    youtube: { cc_load_policy: 0, },
  },
});

document.addEventListener('DOMContentLoaded', () => {
  const quoteElements = document.querySelectorAll(
    '.quote__icon.animated, .quote__quote.animated, .quote__caption.animated',
  );

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    },
    { threshold: 1.0 },
  ); // 1.0 means 100% of the elements is in the viewport

  if (quoteElements) {
    quoteElements.forEach((element) => {
      observer.observe(element);
    });
  }
});

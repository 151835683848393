const isElementInViewport = (
  element: Element,
  windowHeight: number,
): boolean => {
  const elementPosition = element.getBoundingClientRect();
  const threshold = 100;
  return (
    // one part of the element is at least threshold px within the viewport
    (elementPosition.top > 0 &&
      elementPosition.top + threshold < windowHeight) ||
    (elementPosition.bottom < windowHeight &&
      elementPosition.bottom - threshold > 0)
  );
};

export default isElementInViewport;
